<template>
  <div class="rounded-4 text-13 top-0 flex items-center justify-between gap-8 bg-white px-16 py-8 dark:bg-black">
    <div class="flex grow flex-wrap gap-8">
      <slot />
    </div>

    <div class="whitespace-nowrap">
      <fa-icon
        :icon="['fas', 'chevrons-left']"
        class="mr-8 cursor-pointer"
        :class="{ 'text-gray-200 dark:text-gray-600': page === 1, 'text-gray-700 dark:text-gray-100': page !== 1 }"
        @click="firstPage"
      />
      <fa-icon
        :icon="['fas', 'chevron-left']"
        class="mr-8 cursor-pointer"
        :class="{ 'text-gray-200 dark:text-gray-600': page === 1, 'text-gray-700 dark:text-gray-100': page !== 1 }"
        @click="previousPage"
      />

      <span class="text-gray-700 dark:text-gray-100">
        {{ $t('components.paginationFilter', { page, maxPages }) }}
      </span>

      <fa-icon
        :icon="['fas', 'chevron-right']"
        class="ml-8 cursor-pointer"
        :class="{
          'text-gray-200 dark:text-gray-600': page === maxPages,
          'text-gray-700 dark:text-gray-100': page !== maxPages,
        }"
        @click="nextPage"
      />
      <fa-icon
        :icon="['fas', 'chevrons-right']"
        class="ml-8 cursor-pointer"
        :class="{
          'text-gray-200 dark:text-gray-600': page === maxPages,
          'text-gray-700 dark:text-gray-100': page !== maxPages,
        }"
        @click="lastPage"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  modelValue: number;
  maxPages: number;
}>();
const emit = defineEmits<(e: 'update:modelValue', value: number) => void>();

const page = useVModel(props, 'modelValue', emit);

const firstPage = () => {
  page.value = 1;
};

const lastPage = () => {
  page.value = props.maxPages;
};

const previousPage = () => {
  if (page.value > 1) {
    page.value -= 1;
  }
};

const nextPage = () => {
  if (page.value < props.maxPages) {
    page.value += 1;
  }
};
</script>
